import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import MobileDisplayItemV3 from './MobileDisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';
import { AppBar, Tab, Tabs } from '@mui/material';
import CoffeeTwoToneIcon from '@mui/icons-material/CoffeeTwoTone';
import LunchDiningTwoToneIcon from '@mui/icons-material/LunchDiningTwoTone';
import LocalBarTwoToneIcon from '@mui/icons-material/LocalBarTwoTone';
//Read for more about CSS Grid layouts: https://mui.com/system/grid/
import LocalDrinkTwoToneIcon from '@mui/icons-material/LocalDrinkTwoTone';
import SwipeableViews from 'react-swipeable-views';
import MobileBeerMenu from './MobileBeerMenu';
import MobileCocktailMenu from './MobileCocktailMenu';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, my: '3.5em' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const MobileMenu = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <main style={{ backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em' }}>
      {/* Hero unit */}
      <AppBar position="fixed" sx={{top: '0px'}}>
        <Tabs variant='fullWidth'
          sx={{ backgroundColor: 'white', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: '0em' }}
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
        >
          <Tab icon={<LocalDrinkTwoToneIcon />} iconPosition="bottom" label="Beer" {...a11yProps(0)} sx={{minWidth: '50%', maxWidth: '50%', fontSize: '0.75rem'}} />
          <Tab icon={<LocalBarTwoToneIcon />} iconPosition="bottom" label="Cocktails" {...a11yProps(1)} sx={{minWidth: '50%', maxWidth: '50%', fontSize: '0.75rem'}} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        
      >
        <TabPanel value={value} index={0}>
          <MobileBeerMenu />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MobileCocktailMenu />
        </TabPanel>
      </SwipeableViews>
    </main>
  );
}

export default MobileMenu;
