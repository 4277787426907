import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import MobileDisplayItemV3 from './MobileDisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

const MobileBeerMenu = () => {
  const [menuData, setMenuData] = useState<Array<UntappdMenuItem>>();


  async function getMenuData(): Promise<Array<UntappdMenuItem>> {
    var items: Array<UntappdMenuItem> = [];

    fetch(process.env.PUBLIC_URL + '/untappd-menu.json')
      .then((r) => r.json())
      .then((data: UntappdMenuContainer) => {
        //console.log(JSON.stringify(data));
        (data as UntappdMenuContainer).menu.sections.forEach((section) => {
          items = items.concat(section.items);
        });

        setMenuData(items);
        return items;
      })

    return items;
  }

  useEffect(() => {
    //alert( window.innerWidth);
    //alert( window.innerHeight);

    const m = getMenuData(); //run it upon page load

  }, []);


  return (
    <main style={{ backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em' }}>
      {/* Hero unit */}
      <ThemeProvider
        theme={{
          palette: {
            primary: {
              sour: '#DB523A',
              IPA: '#5BB165',
              lager: '#E7A550',
              stout: '#782382',
              other: '#3E1FCE'
            },
          },
        }}
      >
        <Container sx={{ py: 0.0, bgcolor: '#333', color: '#fff', height: '30px', width: '100%', maxWidth: '100vw', top: '0px', position: 'absolute' }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto', gridTemplateRows: 'auto', columnGap: 1, rowGap: 1 }}>
            <Box><Typography sx={{ color: 'white', textAlign: 'center' }} variant="h5" component="h5">Styles:</Typography></Box>
            <Box sx={{ bgcolor: 'primary.IPA', textAlign: 'center', height: '30px', fontWeight: 'bold', paddingTop: '5px' }}>IPA</Box>
            <Box sx={{ bgcolor: 'primary.sour', textAlign: 'center', height: '30px', fontWeight: 'bold', paddingTop: '5px' }}>Sour</Box>
            <Box sx={{ bgcolor: 'primary.lager', textAlign: 'center', height: '30px', fontWeight: 'bold', paddingTop: '5px' }}>Lager</Box>
            <Box sx={{ bgcolor: 'primary.stout', textAlign: 'center', height: '30px', fontWeight: 'bold', paddingTop: '5px' }}>Stout</Box>
            <Box sx={{ bgcolor: 'primary.other', textAlign: 'center', height: '30px', fontWeight: 'bold', paddingTop: '5px' }}>Other</Box>
          </Box>
        </Container>
        <Container sx={{ py: 0.0, px: 0, paddingTop: '30px' }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
            {menuData && menuData.map((menu_item) => <MobileDisplayItemV3 key={menu_item.id} menuItem={menu_item} ></MobileDisplayItemV3>)}
          </Box>
        </Container>
      </ThemeProvider>
    </main>
  );
}

export default MobileBeerMenu;
