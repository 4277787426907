import './App.css';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { UntappdMenuItem } from './untappd-types';


export interface UntappedBoxProps extends BoxProps {
  menuItem: UntappdMenuItem;
}
const DisplayItemV3 = (props: UntappedBoxProps) => {
  const { menuItem, sx, ...other } = props;
  const styleMap: Map<string, string> = new Map<string, string>([
    ['Sour - Berliner Weisse', 'sour'],
    ['Sour - Fruited Berliner Weisse', 'sour'],
    ['Sour - Traditional Gose', 'sour'],
    ['IPA - New England / Hazy', 'IPA'],
    ['IPA - Imperial / Double New England / Hazy', 'IPA'],
    ['IPA - Triple New England / Hazy', 'IPA'],
    ['Pilsner', 'lager'],
    ['Stout - Imperial / Double Milk', 'stout'],
    ['Pilsner - Other', 'lager'],
    ['Pilsner - Italian', 'lager'],
    ['Lager - American', 'lager'],
    ['', '']
  ])

  const bgColorPallette: string = (styleMap.has(menuItem.style) ? styleMap.get(menuItem.style) : 'other') ?? 'other';
  const isLong: boolean = menuItem.name.length > 24 ? true : false;
  const isSuperLong: boolean = menuItem.name.length > 32 ? true : false;
  //const bgColorPallette: string = 'lager';

if (menuItem.name.length == 0) {
  return <Box sx={{
    width: '100%',
    height: '158px',color: '#222',
    bgcolor: '#fff',
    textAlign: 'center', 
    verticalAlign: 'middle',
    borderRadius: 1,
    paddingTop: '50px'
    }}>
      <img width={70} height={60} src='/empty_tap_line.png' />
  </Box>
}

  return (
    <Box
      sx={{
        width: '100%',
        height: '158px',
        color: '#222',
        bgcolor: '#fff',
        borderRadius: 1,
        '& > .MuiBox-root > .MuiBox-root': {
          px: 0.5,
          py: 0.5,
          borderRadius: 0,
          fontSize: '0.875rem',
          fontWeight: '700',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          px: 0,
          py: 0,
          gridTemplateColumns: '12px auto 100px 26px',
          gap: 0.0,
          gridAutoRows: '36px 90px 32px',
          gridTemplateAreas: `"handle_left main prices handle_right" "handle_left desc prices handle_right" "handle_left info prices handle_right"`
        }}
      >
        <Box sx={{ gridArea: 'handle_left', bgcolor: 'primary.' + bgColorPallette, paddingRight: '0px', marginBottom: '4px' }}></Box>
        
        <Box sx={{ gridArea: 'main' }}>
          <Typography variant="h5" component="h2" fontWeight="500" fontSize={isSuperLong ? '1.1rem' : (isLong ? '1.4rem' : '1.6rem')}>{menuItem.name}</Typography>
        </Box>
        
        <Box sx={{ gridArea: 'desc', minWidth: 0, textOverflow: 'ellipsis', display: 'inline-block', overflow: 'hidden' }}>
          <Typography style={{ whiteSpace: "pre-line" }}>{menuItem.description.replace('\\\\', '\n').replace('||', '\n')}</Typography>
        </Box>

        <Box sx={{ gridArea: 'info', display: 'inline' }}>
          <Typography display={"inline-block"} variant="h6" component="h3" sx={{ borderRadius: 1, textAlign: 'center', marginBottom: '8px', marginLeft: '-16px', paddingLeft: '4px', 'width': '40px', backgroundColor: '#303030', color: 'white' }} >{menuItem.tap_number}</Typography>
          <Typography display={"inline"} variant="h6" component="h3" sx={{ marginLeft: '4px'}} >{menuItem.abv}% ABV</Typography>
        </Box>
        <Box sx={{ gridArea: 'handle_right', paddingRight: '0px' }}></Box>

        <Box sx={{ gridArea: 'prices', textAlign: 'right', marginLeft: '0px', marginRight: '-20px', marginTop: '4px' }}>
          {menuItem.containers && menuItem.containers.length > 0 && <Box sx={{borderRadius: 1, 'height': '32px', paddingRight: '6px', marginBottom: '4px', paddingTop: '5px', backgroundColor: '#303030', color: 'white'}} >{menuItem.containers[0].container_size.name.replace('Draft','')} ${menuItem.containers[0].price}</Box>}
          {menuItem.containers && menuItem.containers.length > 1 && <Box sx={{borderRadius: 1, 'height': '32px', paddingRight: '6px', marginBottom: '4px', paddingTop: '5px', backgroundColor: '#303030', color: 'white'}}>{menuItem.containers[1].container_size.name.replace('Draft','')} ${menuItem.containers[1].price}</Box>}
          {menuItem.containers && menuItem.containers.length > 2 && <Box sx={{borderRadius: 1, 'height': '32px', paddingRight: '6px', marginBottom: '4px', paddingTop: '5px', backgroundColor: '#303030', color: 'white'}}>{menuItem.containers[2].container_size.name.replace('Draft','')} ${menuItem.containers[2].price}</Box>}
          {menuItem.containers && menuItem.containers.length > 3 && <Box sx={{borderRadius: 1, 'height': '32px', paddingRight: '6px', marginBottom: '4px', paddingTop: '5px', backgroundColor: '#303030', color: 'white'}}>{menuItem.containers[3].container_size.name.replace('Draft','')} ${menuItem.containers[3].price}</Box>}
        </Box>

      </Box>
    </Box>
  );
}

export default DisplayItemV3;
