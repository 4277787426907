import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import MobileDisplayItemV3 from './MobileDisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';
import MobileCocktailItem from './MobileCocktailItem';
import { CocktailMenuItem } from './menu-types';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

const draft1: CocktailMenuItem = { id: 1, name: 'Gleaming The Cube', style: 'Draft', price: 9, description: 'Ology London Dry Gin, Brovo Pink Vermouth, Cardamom, Ology Floral Tincture' }
const draft2: CocktailMenuItem = { id: 2, name: 'Orthodox Punch', style: 'Draft', price: 10, description: 'Mixed Berry Infused Ology New World Gin, Brovo Pretty Sweet Vermouth, Citric Acid, Turbinado' }
const draft3: CocktailMenuItem = { id: 3, name: 'Enter The Void', style: 'Draft', price: 9, description: 'Ology Vodka, Espresso Syrup, Scrappy’s Lime Bitters, Lemon' }
const draft4: CocktailMenuItem = { id: 4, name: 'Writer’s Block', style: 'Draft', price: 10, description: 'Ology White Rum, Sour Beer Reduction, Bonal Gentiane-Quina, Vanilla' }

const mocktail1: CocktailMenuItem = { id: 11, name: 'Central Hemisphere', style: 'Draft', price: 7, description: 'Grilled Pineapple Agave, Clove, Sarsaparilla, Vanilla, Hibiscus, Soda' }
const mocktail2: CocktailMenuItem = { id: 12, name: 'Earth Tones', style: 'Draft', price: 7, description: 'Oleo Saccharum, Juniper, Rosemary, Sage, Citric and Malic Acid Solution' }

const signature1: CocktailMenuItem = { id: 21, name: 'Indigo Wings', style: 'Signature', price: 11, description: 'Butterfly tea infused Ology Vodka, Lemon Juice, Champagne, Simple' }
const signature2: CocktailMenuItem = { id: 22, name: 'The Busy Beezzzzz', style: 'Signature', price: 12, description: 'Ology London Dry Gin, Lemon Juice, Hibiscus-Lavender Infused Honey' }
const signature3: CocktailMenuItem = { id: 23, name: 'Derby Dayz', style: 'Signature', price: 10, description: 'Ology Small Batch Bourbon, Lemon Juice, Ginger, Bitters' }
const signature4: CocktailMenuItem = { id: 24, name: 'Tiki Julius', style: 'Signature', price: 13, description: 'Ology Single Barrel Golden Rum, Lime Juice, Orange Oleo Saccharum, Brown Sugar Spice, Bitters' }
const signature5: CocktailMenuItem = { id: 25, name: 'Sage Francis', style: 'Signature', price: 12, description: 'Ology New World Gin, Rosemary, Sage, Juniper, Lemon-Lime Oleo Saccharum' }

const birthdayShot: CocktailMenuItem = { id: 31, name: 'Birthday Shot', style: 'Shot', price: 5, description: 'A shot of Ology Birthday Cake Crème Liqueur' }

const classic1: CocktailMenuItem = { id: 41, name: 'Martini', style: 'Cocktail', price: 12, description: 'Choice of Ology Vodka or Gin (London Dry or New World), Cocchi Americano Bianco' }
const classic2: CocktailMenuItem = { id: 41, name: 'Manhattan', style: 'Cocktail', price: 12, description: 'Ology Small Batch Bourbon, Sweet Vermouth, Angostura Bitters' }
const classic3: CocktailMenuItem = { id: 41, name: 'Old Fashioned', style: 'Cocktail', price: 11, description: 'Ology Small Batch Bourbon, Turbinado Syrup, Angostura Bitters' }
const classic4: CocktailMenuItem = { id: 41, name: 'Whiskey Sour', style: 'Cocktail', price: 12, description: 'Ology Small Batch Bourbon, Turbinado Syrup, Lemon, Egg White' }
const classic5: CocktailMenuItem = { id: 41, name: 'Gimlet', style: 'Cocktail', price: 11, description: 'Ology Gin (London Dry or New World), Turbinado Syrup, Lime' }
const classic6: CocktailMenuItem = { id: 41, name: 'Caipirinha', style: 'Cocktail', price: 12, description: 'Ology Small Batch White Rum, Turbinado, Lime, Soda' }


const MobileCocktailMenu = () => {
  return (
    <main style={{ backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em' }}>
      {/* Hero unit */}
        <Container sx={{ py: 0.0, px: 0.25, paddingTop: '0px' }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Draft Cocktails</Typography>
            <MobileCocktailItem key={draft1.id} menuItem={draft1} ></MobileCocktailItem>
            <MobileCocktailItem key={draft2.id} menuItem={draft2} ></MobileCocktailItem>
            <MobileCocktailItem key={draft3.id} menuItem={draft3} ></MobileCocktailItem>
            <MobileCocktailItem key={draft4.id} menuItem={draft4} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Signature Cocktails</Typography>
            <MobileCocktailItem key={signature1.id} menuItem={signature1} ></MobileCocktailItem>
            <MobileCocktailItem key={signature2.id} menuItem={signature2} ></MobileCocktailItem>
            <MobileCocktailItem key={signature3.id} menuItem={signature3} ></MobileCocktailItem>
            <MobileCocktailItem key={signature4.id} menuItem={signature4} ></MobileCocktailItem>
            <MobileCocktailItem key={signature5.id} menuItem={signature5} ></MobileCocktailItem>
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Birthday</Typography>
            <MobileCocktailItem key={birthdayShot.id} menuItem={birthdayShot} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Classics</Typography>
            <MobileCocktailItem key={classic1.id} menuItem={classic1} ></MobileCocktailItem>
            <MobileCocktailItem key={classic2.id} menuItem={classic2} ></MobileCocktailItem>
            <MobileCocktailItem key={classic3.id} menuItem={classic3} ></MobileCocktailItem>
            <MobileCocktailItem key={classic4.id} menuItem={classic4} ></MobileCocktailItem>
            <MobileCocktailItem key={classic5.id} menuItem={classic5} ></MobileCocktailItem>
            <MobileCocktailItem key={classic6.id} menuItem={classic6} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Zero Proof Mocktails</Typography>
            <MobileCocktailItem key={mocktail1.id} menuItem={mocktail1} ></MobileCocktailItem>
            <MobileCocktailItem key={mocktail2.id} menuItem={mocktail2} ></MobileCocktailItem>
          </Box>
        </Container>
    </main>
  );
}

export default MobileCocktailMenu;
